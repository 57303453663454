// 3rd-party modules
import ReCAPTCHA from 'react-google-recaptcha';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import { message } from 'antd';
import { useState } from 'react';

// project modules
import Button from '../../components/shared/button';
import Loader from '../../components/shared/loader';
import { apiCall } from '../../helpers/apiHelper';

//models
import { ApiResponse } from '../../models/response';

// apis
import * as AuthApi from '../../apis/authApi';

// defines
import Config from "../../config";

export default function EmailNotConfirmedPage() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [isCaptchaSuccessful, setIsCaptchaSuccess] = useState(false)
  const abortController = new AbortController();

  const onBackToLoginClick = () => {
    const returnUrl = Config.loginPageUrl;
    const email = searchParams.get("email");

    if (email) {
      navigate({
        pathname: returnUrl,
        search: `?${createSearchParams({ email })}`,
      });
    } else {
      navigate({
        pathname: returnUrl
      });
    }
  };

  const onResendEmailClick = async () => {
    setLoading(true);

    const data = searchParams.get("email");
    const response: ApiResponse = await apiCall(AuthApi.resendEmailConfirmation(data, abortController.signal));

    if (response.success) {
      message.success("Confirmation email resent successfully.");
      message.success("Please check your email to confirm.")
    } else
      message.error(response.error?.value);

    setLoading(false);
  };

  return (
    <>
    {loading && <Loader/>}
    <view data-border="none">
      <section data-position="center">
        <wrapper>
          <group data-space-horizontal="10">
            <group data-direction="column" data-gap="10">
              <text
                data-weight="800"
                data-text-size="xx-large"
                data-wrap="wrap"
              >
                Email Confirmation
              </text>
              <text data-text-size="large" data-wrap="wrap"  data-max-length="300">
                Please confirm your email address first.
              </text>
            </group>
            <group data-direction="column" data-align="start" data-gap="10">
            <group data-space-vertical="10">
              <ReCAPTCHA sitekey={Config.recaptchaSiteKey} onChange={(token) => setIsCaptchaSuccess(!!token)} />
            </group>
            </group>
            <group
              data-gap="10"
              data-direction="column"
              data-space-vertical="50"
              data-max-length="300">
              <Button
                fit
                large
                outline
                disabled={!isCaptchaSuccessful}
                text="Resend the email" onClick={onResendEmailClick} />
              <Button
                fit
                large
                primary
                text="Back To Login" onClick={onBackToLoginClick} />
            </group>
          </group>
        </wrapper>
      </section>
    </view>
    </>
  );
}
