// 3rd-party modules
import moment from "moment";
import { Tooltip, message } from "antd";
import { useEffect, useState } from "react";

// project modules
import Button from "../../shared/button";
import ConfirmationPopup from "../../shared/popup/confirmationPopup";
import DataListView from "../../shared/list/dataListView";
import Loader from "../../shared/loader";
import { apiCall } from "../../../helpers/apiHelper";
import { ListViewType } from "../../shared/list/useListHook";
import { CONSTANTS } from "../../../helpers/defines";
import { DataSourceRequest, FilterDescriptor, PaginationDescriptor, SortDescriptor } from "../../../models/dataSourceRequest";

// apis
import * as AccountSubscriptionApi from '../../../apis/accountSubscriptionApi';

// models
import { Account } from "../../../models/account";
import { AccountSubscription } from "../../../models/accountSubscription";

// defines
type Props = {
  account?: Account;
  refresh?: boolean;
  onCancel?: () => void;
  onSave?: (account: Account) => void;
};

export default function AccountSubscriptions({ account, refresh, onCancel, onSave }: Props) {
  const [currentAccountSubscription, setCurrentAccountSubscription] = useState<AccountSubscription>(new AccountSubscription());
  // const [dataSource, setDataSource] = useState<AccountSubscription[]>([]);
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [isCancelConfirmationPopupOpen, setIsCancelCConfirmationPopupOpen] = useState(false);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const abortController = new AbortController();

  // useEffect(() => {
  //   getPageAsync();
  // }, []);

  useEffect(() => {
    if (reload) {
      // getPageAsync();
      setReload(false);
    }
  }, [reload]);

  // const getPageAsync = async () => {
  //   setDataSource(await getPage())
  // }

  const getPage = async (conditionList?: FilterDescriptor[], sortList?: SortDescriptor[], pagination?: PaginationDescriptor, abortSignal?: AbortSignal) => {
    const request = { conditionList, sortList, pagination } as DataSourceRequest;

    setLoading(true);
    const response = await apiCall(AccountSubscriptionApi.getAccountSubscriptions(account?.accountId!, request, abortSignal));
    setTotalRecords(response.success ? response.extra?.totalCount || 0 : 0);
    setLoading(false);

    const data = response.success ?
      AccountSubscription.toArrayOfClass(response.data?.value || []).map((item: AccountSubscription) => {
        let interval = "";

        if (item.currentPeriodStart && item.currentPeriodEnd) {
          const start = moment(item.currentPeriodStart);
          const end = moment(item.currentPeriodEnd);

          if (end.diff(start, "years") > 0) {
            interval = "Annual"
          } else if (end.diff(start, "months") > 0) {
            interval = "Monthly"
          } else if (end.diff(start, "weeks") > 0) {
            interval = "Weekly"
          } else if (end.diff(start, "days") > 0) {
            interval = "Daily"
          }
        }

        return {
          ...item,
          canceled: !!(moment() > moment(item.currentPeriodEnd) || item.cancelDate),
          interval
        }
      }) :
      [];

    // setDataSource(data);

    return data;
  };

  const onStripeCustomerPortalClick = async () => {
    setLoading(true);
    const response = await apiCall(AccountSubscriptionApi.getCustomerPortalSession(account?.accountId!, abortController.signal));
    setLoading(false);

    if (response.success) {
      const newWindow = window.open(response.data?.value.url, '_blank');

      newWindow?.focus();
    } else
      message.error(response.error?.value);
  };

  const onCancelClick = (accountSubscription: AccountSubscription) => {
    setCurrentAccountSubscription(accountSubscription);
    setIsCancelCConfirmationPopupOpen(true);
  };

  const handleCancelRequest = async () => {
    setLoading(true);
    const response = await apiCall(AccountSubscriptionApi.cancelSubscription(currentAccountSubscription));
    setLoading(false);

    if (response.success) {
      message.success(`Subscription canceled successfully.`);
      setReload(true);
    }

    setIsCancelCConfirmationPopupOpen(false);
    setReload(true);
  };

  const BlockItem: React.FC<{ data: any }> = ({ data }) => {
    return (
      <group data-direction="column" data-border="" data-radius="10" data-contain="">
        <group
          data-space="30"
          data-gap="5"
        >
          <group>
            <text data-weight="700" data-color="main">Subscription</text>
          </group>
          <text data-wrap="wrap">
            Kibosh Device id.: {data.kiboshDeviceReference}
          </text>
          <text data-wrap="wrap">
            Status: {data.status}
          </text>
          {data.profile && data.profile['ProductMetadata'] && data.profile['ProductMetadata']['VpnCount'] && <text data-wrap="wrap">
            # of VPNs: {data.profile['ProductMetadata']['VpnCount']}
          </text>}
          <text data-wrap="wrap">
            {data.interval} {moment(data.currentPeriodStart).format(CONSTANTS.DEFAULT_DATETIME_FORMAT)}{data.currentPeriodEnd && `- ${moment(data.currentPeriodEnd).format(CONSTANTS.DEFAULT_DATETIME_FORMAT)}`}
          </text>
          {!!data.canceled && <text data-wrap="wrap">
            Cancelation Date: {data.cancelDate && moment(data.cancelDate).format(CONSTANTS.DEFAULT_DATETIME_FORMAT)}
          </text>}
        </group>
        <group data-gap="5" data-space="10" data-position="bottom" data-align="center" data-border="" data-wrap="no">
          <Tooltip title="Cancel Subscription">
            <Button
              micro
              icon="delete_history"
              disabled={data.canceled || undefined}
              onClick={() => onCancelClick(data)} />
          </Tooltip>
        </group>
      </group>
    )
  };

  return (
    <>
      {loading && <Loader />}
      <view
        data-scroll=""
        data-space="40"
        data-gap="30">
        <group data-gap="10" data-direction="column">
          <text data-weight="700" data-text-size="xx-large" data-wrap="wrap" data-color="main-dark">Subscriptions</text>
          <text data-wrap="wrap" data-length="300" data-line="1.5" data-light="">Here you can manage all your subscriptions.</text>
        </group>
        <group data-width="auto" data-position="left" data-align="center" data-gap="10">
          <Tooltip title="Refresh" arrow={false}>
            <group data-width="auto">
              <Button
                secondary
                data-length="50"
                data-height="50"
                data-elevation="6"
                rounded
                icon="refresh"
                //text="New"
                onClick={() => setReload(true)}
              />
            </group>
          </Tooltip>
          <separator data-vertical=""></separator>
          <Button
            material
            text="Manage Subscriptions"
            outline
            large
            disabled={totalRecords === 0}
            onClick={() => onStripeCustomerPortalClick()}
          />
        </group>
        <DataListView
          dataPagination={false}
          // dataSource={dataSource}
          getPageAsync={getPage}
          keyField="accountSubscriptionId"
          itemComponent={BlockItem}
          lazyLoadPages={true}
          loading={loading}
          reload={reload}
          renderContainer={false}
          totalRecords={totalRecords}
          view={ListViewType.Block}
        />
      </view>
      {isCancelConfirmationPopupOpen && (
        <ConfirmationPopup
          showButton={false}
          positiveButtonText="Cancel Subscription"
          positiveCallback={handleCancelRequest}
          negativeCallback={() => {
            setIsCancelCConfirmationPopupOpen(false);
          }}
        />
      )}
    </>
  );
}
