// 3rd-party modules
import { Tooltip, message } from "antd";
import { useEffect, useState } from "react";

// project modules
import AccountDeviceMenuModal from "./AccountDeviceMenuModal";
import AccountDeviceModal from "./AccountDeviceModal";
import Button from "../../shared/button";
import ConfirmationPopup from "../../shared/popup/confirmationPopup";
import ContextMenu from "../../shared/contextMenu/contextMenu";
// import DataTable, { TableColumn } from "../../../../components/shared/list/dataTable";
import Loader from "../../shared/loader";
import Select from "../../shared/inputs/select";
import { apiCall } from "../../../helpers/apiHelper";
import { getDeviceSignalRateClassIcon } from "../../../helpers";
// import DataListView from "../../shared/list/dataListView";
import ListView from "../../shared/list/listView";
import { ListViewType } from "../../shared/list/useListHook";

// apis
import * as AccountDeviceApi from '../../../apis/accountDeviceApi';
import * as AccountKiboshDeviceApi from '../../../apis/accountKiboshDeviceApi';
import * as DashApi from '../../../apis/dashApi';

// models
import { Account } from "../../../models/account";
import { AccountDevice } from "../../../models/accountDevice";
import { AccountKiboshDevice } from "../../../models/accountKiboshDevice";
import { DataSourceRequest } from "../../../models/dataSourceRequest";
import { AccountDeviceGroup } from "../../../models/accountDeviceGroup";

type Props = {
  account?: Account;
  accountKiboshDevice?: AccountKiboshDevice;
  refresh?: boolean;
  onCancel?: () => void;
  onSave?: (account: Account) => void;
  onGroupSave?: () => void;
};

export default function AccountDevices({ account, refresh, accountKiboshDevice, onCancel, onSave, onGroupSave }: Props) {
  // const columns: TableColumn<AccountDevice>[] = [
  //   {
  //     title: 'ID',
  //     dataIndex: 'id',
  //     key: 'id',
  //     filterable: !isMobile,
  //     width: 150
  //   },
  //   {
  //     title: 'Device Name',
  //     dataIndex: 'name',
  //     key: 'name',
  //     filterable: !isMobile,
  //     render: (text:any , record:any) => (
  //       <>
  //         <group data-align="center">
  //           <text>{text}</text>
  //           {!!record.numSchedules &&

  //             <Tooltip title={`${record.numSchedules} Schedules`}><div className='button micro' onClick={() => onSelectedTab(record, "internet-schedules")}><icon>schedule</icon></div></Tooltip>
  //           }
  //         </group>
  //       </>)
  //   },
  //   {
  //     title: 'Device IP',
  //     dataIndex: 'ip',
  //     key: 'ip',
  //     filterable: !isMobile,
  //     width: 110
  //   },
  //   {
  //     title: 'Static IP',
  //     dataIndex: 'staticIp',
  //     key: 'staticIp',
  //     filterable: !isMobile,
  //     width: 110
  //   },
  //   // {
  //   //   title: 'WiFi Signal',
  //   //   dataIndex: 'wifiSignal',
  //   //   key: 'wifiSignal',
  //   //   dataType: 'number',
  //   //   width: 90
  //   // },
  //   // {
  //   //   title: 'Ping',
  //   //   dataIndex: 'pingMs',
  //   //   key: 'pingMs',
  //   //   filterable: true,
  //   //   width: 100,
  //   //   render: (text: number) => text > 0 ? `${text.toFixed(2)}ms` : "disconnected"
  //   // },
  //   {
  //     title: !isMobile ? 'Connection Quality' : 'C. Q.',
  //     key: 'connectionQuality',
  //     width: 100,
  //     render: (_, record: any) => (
  //       <group data-justify='center'>
  //         <icon>{getDeviceSignalRateClassIcon(record.pingMs!)}</icon>
  //       </group>
  //     )
  //   },
  //   // {
  //   //   title: 'Active Connections',
  //   //   dataIndex: 'activeConnections',
  //   //   key: 'activeConnections',
  //   //   filterable: true,
  //   //   dataType: 'number',
  //   //   width: 170
  //   // },
  //   {
  //     title: !isMobile ? 'Connection' : 'C.',
  //     dataIndex: 'connectionStatus',
  //     key: 'connectionStatus',
  //     width: 100
  //   },
  //   {
  //     title: !isMobile ? 'Activity Logs' : 'L.',
  //     dataIndex: 'loggingEnabled',
  //     key: 'loggingEnabled',
  //     dataType: 'bool',
  //     width: 110
  //   },
  //   {
  //     title: '',
  //     key: 'action',
  //     width: 60,
  //     render: (_, record: any) => (
  //       <group data-wrap='no' data-gap="5">
  //         <Tooltip title="Edit"><div className='button micro' onClick={() => onEditClick(record)}><icon>edit_square</icon></div></Tooltip>
  //         {/* <Tooltip title="Delete"><div className='button micro' onClick={() => onDeleteClick(record)}><icon>delete</icon></div></Tooltip> */}
  //         <separator vertical="" data-adaptive="desktop"></separator>
  //         <ContextMenu placement="bottomLeft" items={
  //           [
  //             {
  //               text: "Details",
  //               onClick: () => onSelectedTab(record, "details")
  //             },
  //             {
  //               text: "Blocked Sites",
  //               onClick: () => onSelectedTab(record, "blocked-sites")
  //             },
  //             {
  //               text: "Internet Schedules",
  //               onClick: () => onSelectedTab(record, "internet-schedules")
  //             },
  //             {
  //               text: "Activity Logs",
  //               onClick: () => onSelectedTab(record, "activity-logs")
  //             },
  //             {
  //               text: "Bandwidth Logs",
  //               onClick: () => onSelectedTab(record, "bandwidth-logs")
  //             }
  //           ]
  //         }>
  //           <Tooltip title="Tabs"><div className='button micro'><icon>more_vert</icon></div></Tooltip>
  //         </ContextMenu>
  //       </group>
  //     ),
  //   }
  // ];
  const [accountKiboshDevices, setAccountKiboshDevices] = useState<AccountKiboshDevice[]>([]);
  const [currentAccountKiboshDevice, setCurrentAccountKiboshDevice] = useState<AccountKiboshDevice | null>(null);
  const [currentAccountDevice, setCurrentAccountDevice] = useState<AccountDevice>(new AccountDevice());
  const [isDeleteConfirmationPopupOpen, setIsDeleteConfirmationPopupOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingAccountKiboshDevices, setLoadingAccountKiboshDevices] = useState(false);
  const [preSelectedTab, setPreSelectedTab] = useState<string>("");
  const [reload, setReload] = useState<boolean>(false);
  const [showAccountDeviceModal, setShowAccountDeviceModal] = useState(false);
  const [showAccountDeviceMenuModal, setShowAccountDeviceMenuModal] = useState(false);
  // const [totalRecords, setTotalRecords] = useState<number>(0);
  const [dataSource, setDataSource] = useState<any[]>([]);
  let abortController = new AbortController();

  useEffect(() => {
    if (reload) {
      getDataAsync();
      setReload(false);
    }
  }, [reload]);

  useEffect(() => {
    if (refresh) {
      if (!accountKiboshDevice?.kiboshDeviceReference) {
        getAccountKiboshDevicesAsync();
      } else {
        setReload(true);
      }
    }
  }, [refresh]);

  useEffect(() => {
    setReload(true);
  }, [accountKiboshDevice,currentAccountKiboshDevice]);

  const getAccountKiboshDevicesAsync = async () => {
    setAccountKiboshDevices(await getAccountKiboshDevices({}, abortController.signal));
  }

  const getAccountKiboshDevices = async (request?: DataSourceRequest, abortSignal?: AbortSignal) => {
    setLoadingAccountKiboshDevices(true);
    const response = await apiCall(AccountKiboshDeviceApi.getAccountKiboshDevices(account?.accountId!, request, abortSignal));
    setLoadingAccountKiboshDevices(false);

    return response.success ? AccountKiboshDevice.toArrayOfClass(response.data?.value || []) : [];
  };

  const getAccountDeviceGroups = async (abortSignal?: AbortSignal) => {
    const response = await apiCall(DashApi.getDeviceGroups(accountKiboshDevice!.kiboshDeviceReference!, false, abortSignal));

    return response.success ? AccountDeviceGroup.toArrayOfClass(response.data?.value || []) : [];
  };

  const getAccountDevices = async (abortSignal?: AbortSignal) => {
    if (!currentAccountKiboshDevice?.kiboshDeviceReference && !accountKiboshDevice?.kiboshDeviceReference)
      return [];
    const response = await apiCall(DashApi.getClientDevices(accountKiboshDevice?.kiboshDeviceReference ? accountKiboshDevice?.kiboshDeviceReference : currentAccountKiboshDevice?.kiboshDeviceReference!, abortSignal));
    // setTotalRecords(response.success ? response.extra?.totalCount || 0 : 0);

    return response.success ? AccountDevice.toArrayOfClass(response.data?.value || []) : [];
  };

  const getDataAsync = async () => {
    if (!currentAccountKiboshDevice?.kiboshDeviceReference && !accountKiboshDevice?.kiboshDeviceReference)
      return [];

    setLoading(true);
    Promise.all([getAccountDeviceGroups(abortController.signal), getAccountDevices(abortController.signal)]).then(
      (results: any) => {
        const groups = results[0];
        const devices = results[1];

        let data = []
        groups.forEach((groupItem: AccountDeviceGroup) => {
          data.push({
            title: groupItem.name,
            items: devices.filter((x: any) => x.groupId === groupItem.id)
          })
        });

        data.push({
          title: "Un Profiled",
          items: devices.filter((x: any) => !x.groupId)
        })

        data = data.filter(x => x.items.length);

        setDataSource(data);
      }
    ).finally(() => {
      setLoading(false);
    });
  };

  const handleDeleteRequest = async () => {
    setLoading(true);
    const response = await apiCall(AccountDeviceApi.deleteAccountDevice(currentAccountDevice));
    setLoading(false);

    if (response.success) {
      message.success(`Account Device deleted successfully.`);
      setIsDeleteConfirmationPopupOpen(false);
      setReload(true);
    }
  };

  const onAccountDeviceSave = () => {
    setReload(true);
  }

  const onAccountKiboshDeviceChange = (kiboshDeviceReference: any) => {
    setCurrentAccountKiboshDevice(accountKiboshDevices.filter(x => x.kiboshDeviceReference === kiboshDeviceReference)[0]);
    setReload(true)
  }

  const onEditClick = (accountDevice: AccountDevice) => {
    setCurrentAccountDevice({ ...accountDevice });
    setPreSelectedTab("");
    setShowAccountDeviceMenuModal(true);
  };

  // const onDeleteClick = (accountDevice: AccountDevice) => {
  //   setCurrentAccountDevice(accountDevice);
  //   setIsDeleteConfirmationPopupOpen(true);
  // };

  const onSelectedTab = (accountDevice: AccountDevice, selectedTab: any) => {
    if (selectedTab) {
      setCurrentAccountDevice({ ...accountDevice });
      setPreSelectedTab(selectedTab);
      setShowAccountDeviceModal(true);
    }
  };

  const getClientIcon = (clientType: any) : string => {
    let icon = "install_desktop"
    switch (clientType) {
      case "laptop":
        icon = "laptop_windows"
        break;
        case "desktop":
          icon = "computer"
          break;
        case "mobile":
          icon = "smartphone"
          break;
        case "tablet":
          icon = "tablet"
          break;
        case "security-camera":
          icon = "speed_camera"
          break;
        case "thermostat":
          icon = "thermostat"
          break;
        case "doorbell":
          icon = "doorbell"
          break;
        case "lighting":
          icon = "wb_incandescent"
          break;
        case "tv":
          icon = "tv"
          break;
        case "voip-phone":
          icon = "call"
          break;
        case "servers":
          icon = "dns"
          break;
        case "others":
          icon = "devices"
          break;
    }
    return icon;
  }

  const BlockItem: React.FC<{ data: any }> = ({ data }) => {
    return (
      <group data-direction="column" data-border="" data-radius="10" data-contain="" data-background="highlight">
        <group
          data-direction="column"
          // data-gap="10"
          data-space="5"
          data-space-horizontal="10"
          data-adaptive="400"
        >
          <group data-gap="5" data-width="auto" data-align="center" data-justify="start" data-space-horizontal="5">
            <icon data-color={data.connectionStatus === "offline" ? "" : "main"} data-opacity={data.connectionStatus === "offline" ? "50" : undefined} data-icon-size="48">{getClientIcon(data.deviceType)}</icon>
          </group>
          <group data-length="autofit">
            <group data-adaptive="500">
              <group data-direction="column" data-fit="1" data-space="10" data-gap="5">
                <text data-weight="700" data-color="main" data-wrap="wrap">{data.name}</text>
                <text data-wrap="no" data-ellipsis="">
                  Device IP: { !!data.staticIp ? '-' : data.ip}
                </text>
                <text data-wrap="no" data-ellipsis="">
                  Static IP: {data.staticIp || '-'}
                </text>
              </group>
              {/* <group data-direction="column" data-fit="1" data-space="10" data-gap="5">
                <group data-gap="5" data-align="center" data-wrap="no">
                  <text data-ellipsis="">Conn:</text>
                  <text data-color="deep-orange" data-ellipsis="">{data.connectionStatus}</text>
                </group>
                <group data-gap="5" data-align="center" data-wrap="no">
                  <text data-ellipsis="">Logs:</text>
                  <badge data-outline="">
                    <text data-ellipsis="">
                      {data.loggingEnabled ? "ON" : "OFF"}
                    </text>
                  </badge>
                </group>
              </group> */}
            </group>
            <group>
              <group
                data-align="center"
                //data-wrap="no"
                data-position="left"
                data-width="auto"
                data-space="5"
                data-gap="5"
              >
                <group data-width="auto" data-background="main-background" data-radius="3">
                  <icon data-opacity={data.isBlocked ? "": "50"} data-space="5" data-color={data.isBlocked ? "main-dark" : ""}>pause_circle</icon>
                </group>
                <group data-width="auto" data-background="main-background" data-radius="3">
                  <icon data-opacity={!!data.numSetFiltersWithSchedule ? "": "50"} data-space="5" data-color={!!data.numSetFiltersWithSchedule ? "main-dark" : ""}>schedule</icon>
                </group>
                <group data-width="auto" data-background="main-background" data-radius="3">
                  <icon data-color="main" data-background="main-background" data-radius="3" data-space="5" >{getDeviceSignalRateClassIcon(data.pingMs!)}</icon>
                </group>
                <group data-width="auto" data-background="main-background" data-radius="3">
                  <text data-opacity={!!data.loggingEnabled ? "": "50"} data-space="5" data-color={!!data.loggingEnabled ? "main-dark" : ""}>Logs</text>
                </group>
                <group data-width="auto" data-background="main-background" data-radius="3">
                  <text data-opacity={data.connectionStatus !== "offline" ? "": "50"} data-space="5" data-color={data.connectionStatus !== "offline" ? "main-dark" : ""}>{data.connectionStatus}</text>
                </group>
              </group>
              <group
                data-align="center"
                //data-wrap="no"
                data-position="right"
                data-width="auto"
                data-space="5"
              >
                <Tooltip title="Edit">
                  <div className='button micro' data-color="grey" onClick={(e: any) => { e.stopPropagation(); onEditClick(data) }}>
                    <icon>edit_square</icon>
                  </div>
                </Tooltip>
                <separator vertical="" data-adaptive="desktop"></separator>
                <ContextMenu placement="bottomLeft" items={
                  [
                    {
                      text: "Details",
                      onClick: () => onSelectedTab(data, "details")
                    },
                    {
                      text: "Blocked Sites",
                      onClick: () => onSelectedTab(data, "blocked-sites")
                    },
                    {
                      text: "Internet Schedules",
                      onClick: () => onSelectedTab(data, "internet-schedules")
                    },
                    {
                      text: "Activity Logs",
                      onClick: () => onSelectedTab(data, "activity-logs")
                    },
                    {
                      text: "Bandwidth Logs",
                      onClick: () => onSelectedTab(data, "bandwidth-logs")
                    }
                  ]
                }>
                  <Tooltip title="Tabs"><div className='button micro' data-color="grey" onClick={(e: any) => e.stopPropagation()}><icon>more_vert</icon></div></Tooltip>
                </ContextMenu>
              </group>
            </group>
          </group>

        </group>

      </group>
      // <group data-direction="column" data-border="" data-radius="10" data-contain="">
      //   <group
      //     data-space="30"
      //     data-gap="5"
      //     data-wrap="no"
      //     data-direction="column"
      //   >
      //     <group>
      //       <text data-weight="700" data-color="main">{data.name}</text>
      //     </group>
      //     <group data-direction="column" data-gap="5">
      //       <icon data-color="main" data-icon-size="48">{getDeviceSignalRateClassIcon(data.pingMs!)}</icon>
      //     </group>

      //     <text data-wrap="wrap">
      //       Device IP: {data.ip}
      //     </text>
      //     <text data-wrap="wrap">
      //       Static IP: {data.staticIp || '-'}
      //     </text>

      //     <text data-wrap="wrap">
      //       Connection: {data.connectionStatus}
      //     </text>
      //     <text>
      //       Activity Logs: {data.loggingEnabled ? "ON" : "OFF"}
      //     </text>


      //   </group>
      //   <group data-gap="5" data-space="10" data-position="bottom" data-align="center" data-border="" data-wrap="no" data-justify="end">
      //     <Tooltip title="Edit">
      //       <div className='button micro' onClick={(e: any) => { e.stopPropagation(); onEditClick(data) }}>
      //         <icon>edit_square</icon>
      //       </div>
      //     </Tooltip>
      //     <separator vertical="" data-adaptive="desktop"></separator>
      //     <ContextMenu placement="bottomLeft" items={
      //       [
      //         {
      //           text: "Details",
      //           onClick: () => onSelectedTab(data, "details")
      //         },
      //         {
      //           text: "Blocked Sites",
      //           onClick: () => onSelectedTab(data, "blocked-sites")
      //         },
      //         {
      //           text: "Internet Schedules",
      //           onClick: () => onSelectedTab(data, "internet-schedules")
      //         },
      //         {
      //           text: "Activity Logs",
      //           onClick: () => onSelectedTab(data, "activity-logs")
      //         },
      //         {
      //           text: "Bandwidth Logs",
      //           onClick: () => onSelectedTab(data, "bandwidth-logs")
      //         }
      //       ]
      //     }>
      //       <Tooltip title="Tabs"><div className='button micro' onClick={(e: any) => e.stopPropagation()}><icon>more_vert</icon></div></Tooltip>
      //     </ContextMenu>
      //   </group>
      // </group>
    )
  };

  return (
    <>
      {loading && <Loader />}
      <view
        data-scroll=""
        data-space="20"
      >
        <group data-gap="30" data-direction="column">
          <group data-gap="10" data-direction="column">
            <text data-weight="700" data-text-size="xx-large" data-wrap="wrap" data-color="main-dark">Devices</text>
            <text data-wrap="wrap" data-length="300" data-line="1.5" data-light="">Here you can manage all your devices.</text>
          </group>
          <group data-width="auto" data-position="left" data-align="center" data-gap="10">
            {!accountKiboshDevice?.kiboshDeviceReference && (
              <>
                <group data-width="auto" data-position="left" data-align="center" data-gap="10" data-space="10" data-radius="5" data-elevation="1" data-backdrop="">
                  <Select
                    autoComplete=""
                    label="Kibosh Device"
                    labelPosition="left"
                    dataLength="320"
                    onChange={onAccountKiboshDeviceChange}
                    loading={loadingAccountKiboshDevices}
                    allowSearch={true}
                    options={
                      accountKiboshDevices?.map((item) => {
                        return {
                          text: `${item.kiboshDeviceName!} (${item.kiboshDeviceReference!})`,
                          value: item.kiboshDeviceReference!,
                        };
                      }) || []
                    }
                    button={
                      <Button
                        material
                        icon="refresh"
                        onClick={getAccountKiboshDevicesAsync}
                      />
                    }
                  />
                </group>
                <separator data-vertical=""></separator>
              </>
            )}
            <Tooltip title="Refresh" arrow={false}>
              <group data-width="auto">
                <Button
                  secondary
                  data-length="50"
                  data-height="50"
                  data-elevation="6"
                  rounded
                  icon="refresh"
                  //text="New"
                  onClick={() => setReload(true)}
                />
              </group>
            </Tooltip>
          </group>
          {/* <DataListView
            dataPagination={false}
            getPageAsync={getPage}
            onItemClick={onEditClick}
            keyField="id"
            itemComponent={BlockItem}
            data-template="350"
            listProps={{ 'data-gap': '10' }}
            lazyLoadPages={true}
            loading={loading}
            reload={reload}
            renderContainer={false}
            totalRecords={totalRecords}
            view={ListViewType.Block}
          /> */}
          {dataSource.map(item => (
            <group data-gap="5">
              {dataSource.length > 1 &&
                <group data-color="main" data-space-horizontal="10">
                  <text data-weight="700">{item.title}</text>
                </group>
              }
              <ListView
                dataSource={item.items}
                view={ListViewType.Block}
                keyField="id"
                data-template="350"
                listProps={{ "data-gap": "10" }}
                itemComponent={BlockItem}
                onItemClick={onEditClick} />
            </group>
            ))}
        </group>


      </view>

      {/* <view className="table_cont" data-background="none">
        <group
          data-space="15"
          data-gap="20"
          data-border=""
          data-background="highlight"
        >
          <Button
            data-position="right"
            material
            icon="refresh"
            text="Refresh"
            outline
            onClick={() => setReload(true)}
          />
        </group>
        <DataTable
          columns={columns}
          bordered={true}
          customPagination={true}
          reload={reload}
          dataPagination={true}
          getPageAsync={getPage}
          loading={loading}
          rowKey={(record) => `${record.id}`}
          showTotal={true}
          size="small"
          totalRecords={totalRecords}
          onRow={(record) => ({
            onDoubleClick: () => onEditClick(record),
            style: { cursor: "pointer" },
          })}
        />
      </view> */}
      {!!showAccountDeviceModal && (
        <AccountDeviceModal
          open={showAccountDeviceModal}
          account={account!}
          accountDevice={currentAccountDevice}
          accountKiboshDevice={
            accountKiboshDevice || currentAccountKiboshDevice!
          }
          preSelectedTab={preSelectedTab}
          onClose={() => setShowAccountDeviceModal(false)}
          onSave={(e) => onAccountDeviceSave()}
          onGroupSave={() => { if (onGroupSave) onGroupSave() }}
        />
      )}
      {!!showAccountDeviceMenuModal && (
        <AccountDeviceMenuModal
          open={showAccountDeviceMenuModal}
          account={account!}
          accountDevice={currentAccountDevice}
          accountKiboshDevice={
            accountKiboshDevice || currentAccountKiboshDevice!
          }
          onClose={() => setShowAccountDeviceMenuModal(false)}
          onSave={() => onAccountDeviceSave()}
          onGroupSave={() => { if (onGroupSave) onGroupSave() }}
        />
      )}
      {isDeleteConfirmationPopupOpen && (
        <ConfirmationPopup
          showButton={false}
          positiveButtonText="Delete"
          positiveCallback={handleDeleteRequest}
          negativeCallback={() => {
            setIsDeleteConfirmationPopupOpen(false);
          }}
        />
      )}
    </>
  );
}
