// 3rd-party modules
import { useEffect, useState } from "react";

// project modules
import { apiCall } from "../../../helpers/apiHelper";
import ListView from "../../shared/list/listView";
import Loader from "../../shared/loader";
import { ListViewType } from "../../shared/list/useListHook";
import Ripple from "../../shared/additionalComponents/Ripple";
import Marquee from "../../shared/additionalComponents/Marquee";
import { Text } from "../../shared/text";
import Popup from '../../shared/popup/popup';

// apis
import * as DashApi from '../../../apis/dashApi';

// models
import { Account } from '../../../models/account';
import { AccountDevice } from "../../../models/accountDevice";
import { AccountKiboshDevice } from '../../../models/accountKiboshDevice';
import AccountDeviceModal from "./AccountDeviceModal";
import Input from "../../shared/inputs/input";
import { AccountDeviceGroup } from "../../../models/accountDeviceGroup";
import AccountDeviceGroupModal from "../device-groups/AccountDeviceGroupModal";
import Button from "../../shared/button";
import AccountDeviceGroupMenuModal from "../device-groups/AccountDeviceGroupMenuModal";

type Props = {
  account: Account;
  accountDevice: AccountDevice;
  accountKiboshDevice: AccountKiboshDevice;
  closeOnSave?: boolean;
  modalTitle?: string;
  open: boolean;
  showGroupDetails?: boolean;
  onClose?: () => void;
  onSave?: () => void;
  onGroupSave?: () => void;
};

export default function AccountDeviceMenuModal({ account, accountDevice, accountKiboshDevice, closeOnSave = false, modalTitle = "", open, showGroupDetails = true, onClose, onSave, onGroupSave }: Props) {
  const [preSelectedTab, setPreSelectedTab] = useState<string>("");
  const [currentAccountDevice, setCurrentAccountDevice] = useState<AccountDevice>(accountDevice);
  const [showAccountDeviceModal, setShowAccountDeviceModal] = useState(false);

  const [accountDeviceGroupLoading, setAccountDeviceGroupLoading] = useState(false);
  const [currentAccountDeviceGroup, setCurrentAccountDeviceGroup] = useState<AccountDeviceGroup | null>(null);
  const [deviceGroupName, setDeviceGroupName] = useState<string>("");
  const [showAccountDeviceGroupMenuModal, setShowAccountDeviceGroupMenuModal] = useState(false);
  const [showAccountDeviceGroupModal, setShowAccountDeviceGroupModal] = useState(false);


  const [loading, setLoading] = useState<boolean>(false);

  const [listItems, setListItems] = useState<any[]>([
    {
      id: 'details',
      name: 'Details',
      icon: "info",
      isActive: true
    },
    {
      id: 'blocked-sites',
      name: 'Filters',
      icon: "app_blocking",
      isActive: true
    },
    {
      id: 'internet-schedules',
      name: 'Scheduler',
      icon: "event_available",
      isActive: true
    },
    {
      id: 'internet-pause',
      name: 'Pause',
      icon: "signal_cellular_pause",
      isActive: true
    },
    {
      id: 'activity-logs',
      name: 'History',
      icon: "history",
      isActive: true
    },
    {
      id: 'bandwidth-logs',
      name: 'Bandwidth',
      icon: "settings_ethernet",
      isActive: true
    }
  ]);

  const abortController = new AbortController();


  useEffect(() => {
    if (open) {
      setListItems(
        [
          {
            id: 'details',
            name: 'Details',
            icon: "info",
            isActive: true
          },
          {
            id: 'blocked-sites',
            name: `Filters ${!!currentAccountDevice.groupId ? '(Managed by Profile)' : ''}`,
            icon: "app_blocking",
            isActive: !currentAccountDevice.groupId
          },
          {
            id: 'internet-schedules',
            name: `Scheduler ${!!currentAccountDevice.groupId ? '(Managed by Profile)' : ''}`,
            icon: "event_available",
            isActive: !currentAccountDevice.groupId
          },
          {
            id: 'internet-pause',
            name: `Pause ${!!currentAccountDevice.groupId ? '(Managed by Profile)' : ''}`,
            icon: "signal_cellular_pause",
            isActive: !currentAccountDevice.groupId
          },
          {
            id: 'activity-logs',
            name: 'History',
            icon: "history",
            isActive: true
          },
          {
            id: 'bandwidth-logs',
            name: 'Bandwidth',
            icon: "settings_ethernet",
            isActive: true
          }
        ]
      )
    }
  }, [open]);

  useEffect(() => {
    // setIsUnblocked(!accountDevice.isBlocked);
    if (currentAccountDevice.groupId) {
      getAccountDeviceGroupAsync();
    }
  }, [currentAccountDevice]);

  useEffect(() => {
    setDeviceGroupName(currentAccountDeviceGroup?.name || "")
  }, [currentAccountDeviceGroup]);

  const onAccountDeviceSave = (e?: AccountDevice) => {
    if (e) {
      setLoading(true)
      setCurrentAccountDevice(e)
      setLoading(false)
      if (onSave) onSave();
    }
  }

  const onCancel = () => {
    abortController.abort();

    if (onClose) onClose();
  };

  const onSelectedTab = (selectedTab: any) => {
    if (selectedTab) {
      setPreSelectedTab(selectedTab);
      setShowAccountDeviceModal(true);
    }
  };

  const onEditGroupClick = () => {
    // setShowAccountDeviceGroupModal(true);
    setShowAccountDeviceGroupMenuModal(true);
  };

  const onAccountDeviceGroupSave = async (e?: AccountDeviceGroup) => {
    if (onSave) {
      onSave();
    }
    if (onGroupSave) {
      onGroupSave();
    }
  }

  const getAccountDeviceGroupAsync = async () => {
    setCurrentAccountDeviceGroup(await getAccountDeviceGroup() || null);
  }

  const getAccountDeviceGroup = async () => {
    if (!accountKiboshDevice.kiboshDeviceReference || !currentAccountDevice.groupId) {
      return;
    }

    setAccountDeviceGroupLoading(true);
    const response = await apiCall(DashApi.getDeviceGroupById(accountKiboshDevice.kiboshDeviceReference!, currentAccountDevice.groupId!, true, abortController.signal));
    setAccountDeviceGroupLoading(false);

    return response.success ? AccountDeviceGroup.toClass(response.data?.value) : null;
  };

  const ListItem: React.FC<{ data: any }> = ({ data }) => (
    <Ripple>
      <group
        data-align="center"
        data-space="5"
        data-gap="5"
        data-wrap="no"
        //data-radius="5"
        data-contain=""
        data-cursor="pointer"
        data-disabled={data.isActive ? undefined : "grayscale"}>
        <group data-ratio="1:1" data-length="50" data-shrink="no" data-index="1" data-radius="5" data-color="main" >
          <icon data-icon-size="48" data-position="center">{data.icon}</icon>
        </group>
        <group data-direction="column" data-contain="" data-space="5" data-index="1">
          <Text data-weight="700" data-ellipsis="">
            {data.name}
          </Text>
          {!!data.description && <Marquee>
            <Text data-ellipsis="" data-light="">
              {data.description}
            </Text>
          </Marquee>}
        </group>
        {/* <separator data-vertical=""></separator> */}
        <group
          data-align="center"
          data-wrap="no"
          data-position="right"
          data-width="auto"
          data-index="1">
          <group data-space="10">
            <icon data-opacity="30">chevron_right</icon>
          </group>
        </group>
      </group>
    </Ripple>
  );

  return (
    <Popup
      adaptive
      title={modalTitle || `Account: ${account.accountName} - Kibosh Device: ${accountKiboshDevice.kiboshDeviceName} - ${(!currentAccountDevice.id ? "New Device" : `Device Name: ${currentAccountDevice.name}`)}`}
      onCancel={onCancel}
      onClose={onCancel}
      noCommandbar={true}
      fixSize="medium"
    >
      {loading &&
        <Loader />
      }
      <group>
        <group data-space="15">
          <group data-contain="" data-radius="10" data-elevation="2">
            <ListView
              data-template="400"
              view={ListViewType.List}
              dataSource={listItems}
              onItemClick={(item: any) => item.isActive ? onSelectedTab(item.id) : null}
              keyField="id"
              //data-space="5"
              listProps={{ "data-gap": "1" }}
              itemComponent={ListItem}
            />
          </group>
          {!!currentAccountDeviceGroup && <group
            data-space="10"
            data-align="center"
            data-gap="10"
            data-border="top"
          >
            <Button primary material disabled={!currentAccountDeviceGroup ? true : undefined} onClick={onEditGroupClick}>
              <text>Edit {deviceGroupName}'s Profile</text>
            </Button>
          </group>}
        </group>

      </group>

      {!!showAccountDeviceModal && (
        <AccountDeviceModal
          open={showAccountDeviceModal}
          account={account!}
          accountDevice={currentAccountDevice}
          accountKiboshDevice={accountKiboshDevice!}
          preSelectedTab={preSelectedTab}
          showTabs={false}
          showGroupDetails={showGroupDetails}
          onClose={() => setShowAccountDeviceModal(false)}
          onSave={(e) => onAccountDeviceSave(e)}
          onGroupSave={() => { if (onGroupSave) onGroupSave() }}
        />
      )}
      {accountDeviceGroupLoading && <Loader />}
      {!!showAccountDeviceGroupModal && (
        <AccountDeviceGroupModal
          open={showAccountDeviceGroupModal}
          account={account!}
          accountDeviceGroup={currentAccountDeviceGroup!}
          accountKiboshDevice={accountKiboshDevice}
          preSelectedTab=""
          onClose={() => setShowAccountDeviceGroupModal(false)}
          onSave={(e) => onAccountDeviceGroupSave(e)}
        />
      )}
      {!!showAccountDeviceGroupMenuModal && (
        <AccountDeviceGroupMenuModal
          open={showAccountDeviceGroupMenuModal}
          account={account!}
          accountDeviceGroup={currentAccountDeviceGroup!}
          accountDeviceGroups={[currentAccountDeviceGroup!]}
          accountKiboshDevice={accountKiboshDevice!}
          onClose={() => setShowAccountDeviceGroupMenuModal(false)}
          onSave={onAccountDeviceGroupSave}
        />
      )}
    </Popup>
  );
}
