// 3rd-party modules
import { Tooltip, message } from "antd";
import { useEffect, useState } from "react";

// project modules
import AccountKiboshDeviceMenuModal from "./AccountKiboshDeviceMenuModal";
import AccountKiboshDeviceModal from "./AccountKiboshDeviceModal";
import Button from "../../shared/button";
import ConfirmationPopup from "../../shared/popup/confirmationPopup";
import ContextMenu from "../../shared/contextMenu/contextMenu";
import Loader from "../../shared/loader";
import ListView from "../../shared/list/listView";
import { apiCall } from "../../../helpers/apiHelper";
import { ListViewType } from "../../shared/list/useListHook";
import { Text } from "../../shared/text";

// apis
import * as AccountKiboshDeviceApi from '../../../apis/accountKiboshDeviceApi';
import * as DashApi from '../../../apis/dashApi';

// models
import { Account } from "../../../models/account";
import { DataSourceRequest, FilterDescriptor, PaginationDescriptor, SortDescriptor } from "../../../models/dataSourceRequest";
import { AccountKiboshDevice } from "../../../models/accountKiboshDevice";

type Props = {
  account?: Account;
  refresh?: boolean;
  onCancel?: () => void;
  onSave?: (account: Account) => void;
};

export default function AccountKiboshDevices({ account, refresh, onCancel, onSave }: Props) {
  const [currentAccountKiboshDevice, setCurrentAccountKiboshDevice] = useState<AccountKiboshDevice>(new AccountKiboshDevice());
  const [loading, setLoading] = useState(false);
  const [isDeleteConfirmationPopupOpen, setIsDeleteConfirmationPopupOpen] = useState(false);
  const [isResetPasswordConfirmationPopupOpen, setIsResetPasswordConfirmationPopupOpen] = useState(false);
  const [preSelectedTab, setPreSelectedTab] = useState<string>("");
  const [reload, setReload] = useState<boolean>(false);
  const [showAccountKiboshDeviceModal, setShowAccountKiboshDeviceModal] = useState(false);
  const [showAccountKiboshDeviceMenuModal, setShowAccountKiboshDeviceMenuModal] = useState(false);
  // const [totalRecords, setTotalRecords] = useState<number>(0);
  const [dataSource, setDataSource] = useState<AccountKiboshDevice[]>([]);

  useEffect(() => {
    getPageAsync();
  }, []);

  useEffect(() => {
    if (reload) {
      getPageAsync();
      setReload(false);
    }
  }, [reload]);

  const getPageAsync = async () => {
    setDataSource(await getPage())
  }

  const getPage = async (conditionList?: FilterDescriptor[], sortList?: SortDescriptor[], pagination?: PaginationDescriptor, abortSignal?: AbortSignal) => {
    const request = { conditionList, sortList, pagination } as DataSourceRequest;

    setLoading(true);

    const response = await apiCall(AccountKiboshDeviceApi.getAccountKiboshDevices(account?.accountId!, request, abortSignal));
    const dataSource = response.success ? AccountKiboshDevice.toArrayOfClass(response.data?.value || []) : [];

    if (!dataSource.filter(x => x.kiboshDeviceType?.toLocaleLowerCase() === "router").length) {
      dataSource.push({
        accountKiboshDeviceId: -1,
        kiboshDeviceName: "Router",
        kiboshDeviceReference: "Inactive",
        kiboshDeviceType: "Router",
        inactive: true
      });
    }
    if (!dataSource.filter(x => x.kiboshDeviceType?.toLocaleLowerCase() === "vpn").length) {
      dataSource.push({
        accountKiboshDeviceId: -2,
        kiboshDeviceName: "VPN",
        kiboshDeviceReference: "Inactive",
        kiboshDeviceType: "VPN",
        inactive: true
      });
    }
    // if (!dataSource.filter(x => x.kiboshDeviceType?.toLocaleLowerCase() === "dns").length) {
    //   dataSource.push({
    //     accountKiboshDeviceId: -3,
    //     kiboshDeviceName: "DNS",
    //     kiboshDeviceReference: "Inactive",
    //     kiboshDeviceType: "DNS",
    //     inactive: true
    //   });
    // }

    setLoading(false);

    return dataSource;
  };

  const handleDeleteRequest = async () => {
    setLoading(true);
    const response = await apiCall(AccountKiboshDeviceApi.deleteAccountKiboshDevice({ ...currentAccountKiboshDevice, kiboshDeviceType: undefined })); // kiboshDeviceType is not part of data-model
    setLoading(false);

    if (response.success) {
      message.success(`Account Kibosh Device deleted successfully.`);
      setReload(true);
    }
    setIsDeleteConfirmationPopupOpen(false);

    setReload(true);
  };

  const handleResetPasswordRequest = async () => {
    setLoading(true);
    const response = await apiCall(DashApi.resetAccountKiboshDevicePassword(currentAccountKiboshDevice.kiboshDeviceReference!));
    setLoading(false);

    if (response.success) {
      message.success(`Account Kibosh Device password reseted successfully.`);
      setReload(true);
    }
    setIsResetPasswordConfirmationPopupOpen(false);
  };

  const onAccountKiboshDeviceSave = () => {
    setReload(true);
  }

  const onChangePasswordClick = (accountKiboshDevice: AccountKiboshDevice) => {
    setCurrentAccountKiboshDevice(accountKiboshDevice);
    setIsResetPasswordConfirmationPopupOpen(true);
  };

  const onDeleteClick = (accountKiboshDevice: AccountKiboshDevice) => {
    setCurrentAccountKiboshDevice(accountKiboshDevice);
    setIsDeleteConfirmationPopupOpen(true);
  };

  const onEditClick = (accountKiboshDevice: AccountKiboshDevice) => {
    setCurrentAccountKiboshDevice({ ...accountKiboshDevice });
    setPreSelectedTab("");
    setShowAccountKiboshDeviceMenuModal(true);
  };

  const onNewClick = () => {
    setCurrentAccountKiboshDevice(new AccountKiboshDevice());
    setPreSelectedTab("");
    setShowAccountKiboshDeviceModal(true);
  };

  const onSelectedTab = (accountKiboshDevice: AccountKiboshDevice, selectedTab: any) => {
    if (selectedTab) {
      setCurrentAccountKiboshDevice({ ...accountKiboshDevice });
      setPreSelectedTab(selectedTab);
      setShowAccountKiboshDeviceModal(true);
    }
  };

  const BlockItem: React.FC<{ data: any }> = ({ data }) => (
    <group
      data-direction="column"
      data-contain=""
      data-border=""
      data-radius="10"
      data-background="context"
      data-cursor="pointer"
      className={!!data.inactive ? "inactive" : ""}
    >
      <group data-direction="column" data-contain="" data-gap="5" data-space="20">
        <Text
          data-weight="700"
          data-ellipsis=""
          data-color="main"
        >
          {data.kiboshDeviceName}
        </Text>
        <Text data-wrap="wrap" data-clamp="3" data-line="1.5">
          Type: {data.kiboshDeviceType}
        </Text>
        <Text data-wrap="wrap" data-clamp="3" data-line="1.5">
          Reference: {data.kiboshDeviceReference}
        </Text>
      </group>
      <separator data-horizontal="" data-margin="none"></separator>
      <group data-gap="5" data-space="10" data-align="center" data-justify="end" >
        {!data.inactive &&
          <>
            {/* <Tooltip title="Reset Password"><div className='button micro' onClick={(e: any) => {e.stopPropagation(); onChangePasswordClick(data)}}><icon>key</icon></div></Tooltip> */}
            {/* <separator vertical="" data-adaptive="desktop"></separator> */}
            {/* <Tooltip title="Edit"><div className='button micro' onClick={() => onEditClick(data)}><icon>edit</icon></div></Tooltip> */}
            {/* <Tooltip title="Delete"><div className='button micro' onClick={(e: any) => {e.stopPropagation(); onDeleteClick(data)}}><icon>delete</icon></div></Tooltip> */}
            <separator vertical="" data-adaptive="desktop"></separator>
            <ContextMenu placement="bottomLeft" items={
              [
                {
                  text: "Details",
                  onClick: () => onSelectedTab(data, "details")
                },
                {
                  text: "Devices",
                  onClick: () => onSelectedTab(data, "devices")
                },
                {
                  text: "Profiles",
                  onClick: () => onSelectedTab(data, "device-groups")
                },
                {
                  text: "Blocked Sites",
                  onClick: () => onSelectedTab(data, "blocked-sites")
                },
                {
                  text: "Activity Logs",
                  onClick: () => onSelectedTab(data, "activity-logs")
                },
                {
                  text: "Internet Schedules",
                  onClick: () => onSelectedTab(data, "internet-schedules")
                },
                {
                  text: "Bandwidth Logs",
                  onClick: () => onSelectedTab(data, "bandwidth-logs")
                },
                {
                  text: "Settings",
                  onClick: () => onSelectedTab(data, "settings")
                }
              ]
            }>
              <Tooltip title="Tabs"><div className='button micro' onClick={(e: any) => e.stopPropagation()}><icon>more_vert</icon></div></Tooltip>
            </ContextMenu>
          </>}
      </group>
    </group>
  );

  return (
    <>
      {loading && <Loader />}
      <view
        data-scroll=""
        data-space="40"
        data-gap="30">
        <group data-gap="10" data-direction="column">
          <text data-weight="700" data-text-size="xx-large" data-wrap="wrap" data-color="main-dark">Kibosh Devices</text>
          <text data-wrap="wrap" data-length="400" data-line="1.5" data-light="">This is where you can manage all the details about your kibosh devices. Add new kibosh devices, edit existing kibosh devices, and remove outdated information.</text>
        </group>
        <group data-width="auto" data-position="left" data-gap="10" data-align="center">
          <Tooltip title="Create New" arrow={false}>
            <group data-width="auto">
              <Button
                secondary
                data-length="50"
                data-height="50"
                data-elevation="6"
                rounded
                icon="add"
                onClick={onNewClick}
              />
            </group>
          </Tooltip>
          <separator data-vertical=""></separator>
          <Tooltip title="Refresh" arrow={false}>
            <group data-width="auto">
              <Button
                outline
                data-length="50"
                data-height="50"
                data-elevation="6"
                rounded
                icon="refresh"
                onClick={() => setReload(true)}
              />
            </group>
          </Tooltip>
        </group>
        <ListView
          dataSource={dataSource}
          view={ListViewType.Block}
          keyField="kiboshDeviceReference"
          onItemClick={onEditClick}
          data-template="260"
          listProps={{ 'data-gap': '10' }}
          itemComponent={BlockItem}
        />
      </view>
      {!!showAccountKiboshDeviceMenuModal && (
        <AccountKiboshDeviceMenuModal
          open={showAccountKiboshDeviceMenuModal}
          closeOnSave={true}
          account={account!}
          accountKiboshDevice={currentAccountKiboshDevice}
          onClose={() => setShowAccountKiboshDeviceMenuModal(false)}
          onSave={onAccountKiboshDeviceSave}
        />
      )}

      {!!showAccountKiboshDeviceModal && (
        <AccountKiboshDeviceModal
          open={showAccountKiboshDeviceModal}
          closeOnSave={true}
          showTabs={false}
          account={account!}
          accountKiboshDevice={currentAccountKiboshDevice}
          preSelectedTab={preSelectedTab}
          onClose={() => setShowAccountKiboshDeviceModal(false)}
          onSave={(e) => onAccountKiboshDeviceSave()}
        />
      )}
      {isDeleteConfirmationPopupOpen && (
        <ConfirmationPopup
          showButton={false}
          positiveButtonText="Delete"
          positiveCallback={handleDeleteRequest}
          negativeCallback={() => {
            setIsDeleteConfirmationPopupOpen(false);
          }}
        />
      )}
      {isResetPasswordConfirmationPopupOpen && (
        <ConfirmationPopup
          showButton={false}
          positiveButtonText="Reset Password"
          positiveCallback={handleResetPasswordRequest}
          negativeCallback={() => {
            setIsResetPasswordConfirmationPopupOpen(false);
          }}
        />
      )}
    </>
  );
}
