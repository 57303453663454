// 3rd-party modules
import { message, Tooltip } from "antd";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";

// project modules
import Button from "../shared/button";
import Input from "../shared/inputs/input";
import Loader from "../shared/loader";
import Select from "../shared/inputs/select";
import yup from "../../plugins/yup";
import { apiCall } from "../../helpers/apiHelper";

// apis
import * as AccountKiboshDeviceApi from '../../apis/accountKiboshDeviceApi';
import * as CommonValueApi from '../../apis/commonValueApi';

// models
import { ApiResponse } from "../../models/response";
import { accountKiboshDeviceInsertViewModel } from "../../models/types/accountKiboshDevice";
import { AccountKiboshDevice } from "../../models/accountKiboshDevice";
import { CommonValue } from "../../models/commonValue";

type Props = {
  accountId?: number;
  cancelText?: string;
  okText?: string;
  refresh?: boolean;
  selectedDevice?: AccountKiboshDevice | null;
  onCancel?: () => void;
  onSave?: (kiboushDeviceDetails?: AccountKiboshDevice) => void;
};

export default function AccountDevice({ accountId, cancelText = "Cancel", okText = "Save Changes", refresh, selectedDevice, onCancel, onSave }: Props) {
  const schema = yup.object().shape({
    kiboshDeviceName: yup.string().label("Device Name").max(256).required(),
    kiboshDeviceReference: yup.string().label("Device Reference").max(256).required(),
    kiboshDeviceTypeId: yup.string().label("Device Type").required(),
  });
  const { control, handleSubmit } = useForm<accountKiboshDeviceInsertViewModel | any>({
    resolver: yupResolver(schema),
    defaultValues: selectedDevice
  });
  const [deviceTypes, setDeviceTypes] = useState<CommonValue[]>([]);
  const [loading, setLoading] = useState(false);
  const [loadingDevicesTypeList, setLoadingDevicesTypeList] = useState(false);

  const abortController = new AbortController();

  useEffect(() => {
    getDeviceTypesAsync();
  }, []);

  const getDeviceTypesAsync = async () => {
    const data = await getDeviceTypes(abortController.signal);

    setDeviceTypes(data.filter(x => x.value !== "dns"));
  }

  const getDeviceTypes = async (abortSignal?: AbortSignal) => {
    setLoadingDevicesTypeList(true);
    const response = await apiCall(CommonValueApi.getDeviceTypes(abortSignal));
    setLoadingDevicesTypeList(false);

    return response.success ? CommonValue.toArrayOfClass(response.data?.value || []) : [];
  }

  const onCancelClick = () => {
    if (onCancel) {
      onCancel();
    }
  }

  const onSubmit: SubmitHandler<accountKiboshDeviceInsertViewModel> = async (formData: accountKiboshDeviceInsertViewModel) => {
    let response: ApiResponse;

    setLoading(true);

    if (!selectedDevice?.accountKiboshDeviceId) {
      //TODO: Make sure Kibosh device has not regestered before
      formData.accountId = accountId!;
      formData.accountKiboshDeviceId = 0;

      response = await apiCall(AccountKiboshDeviceApi.insertAccountKiboshDevice(formData, abortController.signal));
    } else
      response = await apiCall(AccountKiboshDeviceApi.updateAccountKiboshDevice(formData, abortController.signal));

    if (response.success) {
      message.success(`Kibosh Device ${!selectedDevice?.accountKiboshDeviceId ? 'added' : 'edited'} successfully.`);

      if (onSave) onSave(AccountKiboshDevice.toClass(response.data?.value));
    } else
      message.error(response.error?.value);

    setLoading(false);
  };

  return (
    <>
      {loading && <Loader />}
      <form
        data-length="auto"
        data-type="group"
      //data-gap="20"
      >
        <space data-height="40"></space>
        <group
          //data-space-vertical="20"
          data-direction="column"
          data-align="start"
          data-gap="10"
        >
          <group data-max-length="600">
            <Select
              control={control}
              label="Device Type"
              labelPosition="left"
              dataLength="forcefit"

              dataFit="1"
              name="kiboshDeviceTypeId"
              allowSearch={true}
              loading={loadingDevicesTypeList}
              options={
                deviceTypes?.map((item) => {
                  return {
                    text: item.valueCaption!,
                    value: item.commonValueId!,
                  };
                }) || []
              }
            />
          </group>
          <group data-gap="10" data-max-length="600">
            <Input
              control={control}
              name="kiboshDeviceName"
              label="Create Device Name"
              dataLength="forcefit"
              //dataMaxLength="300"
              dataFit="1"
              size="large"
            />
            <group data-align="end" data-gap="10">
              <Input
                control={control}
                name="kiboshDeviceReference"
                label="Router ID"
                dataLength="auto"
                //dataMaxLength="300"
                dataFit="1"
                size="large"
              />
              <Tooltip title="Your Kibosh Router ID is located under your Kibosh Router."><div className='button micro' data-color="gray"><icon>info</icon></div></Tooltip>
            </group>
          </group>
        </group>

        <space data-height="40"></space>
        <group data-length="auto" data-direction="row" data-gap="10">
          {/*<separator horizontal=""></separator>*/}
          {/* <group
            data-space="10"
            data-align="center"
            data-gap="10"
            data-wrap="no"
            //   data-type="grid"
          > */}

          <Button large material outline data-length="forcefit" onClick={onCancelClick}>
            <text>{cancelText}</text>
          </Button>
          <Button large material primary data-length="forcefit" onClick={handleSubmit(onSubmit)}>
            <text>{okText}</text>
          </Button>
        </group>
      </form>
    </>
  );
}
