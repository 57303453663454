import { message } from "antd";
import { useState } from "react";
import { useNavigate } from 'react-router-dom';

// project modules
import AccountForm from "../../components/new-subscription-steps/AccountForm";
import { apiCall } from "../../helpers/apiHelper";
import { trimStringProps } from "../../helpers/objectHelper";

// apis
import * as AuthApi from '../../apis/authApi';

// models
import { ApiResponse } from "../../models/response";
import { accountRegisterViewModel } from "../../models/types/auth";

export default function SignupPage() {
  const [loading, setLoading] = useState(false);
  const abortController = new AbortController();
  const navigate = useNavigate();

  const onCancel = () => {
    navigate({
      pathname: "/auth/login",
    });
  };

  const onSave = async (formData: accountRegisterViewModel) => {
    setLoading(true);

    formData.userName = formData.email;

    const response: ApiResponse = await apiCall(AuthApi.register(trimStringProps(formData), abortController.signal));

    if (response.success) {
      message.success("Your account created successfully");
      message.success("Please check your email to confirm.")

      navigate(`/not-confirmed-email?email=${formData.userName}`);
    } else
      message.error(response.error?.value);

    setLoading(false);
  };

  return (
    <>
    <view data-border="none">
      <section>
        <wrapper>
          <group data-space-horizontal="10">
            <AccountForm
              cancelText="Cancel"
              okText="Sign up"
              parentLoading={loading}
              onCancel={onCancel}
              onSave={(formData) => onSave(formData!)} />
          </group>
        </wrapper>
      </section>
    </view>
    </>
  );
}
