// 3rd-party modules
import { Steps } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

// project modules
import AccountDevice from "./AccountDevice";
import AccountPaymentVersion2 from "./AccountPaymentVersion2";
import AccountSubscription from "./AccountSubscription";
import ConfirmationPopup from "../shared/popup/confirmationPopup";
import { apiCall } from "../../helpers/apiHelper";
import { RootState } from "../../stores";

// apis
import * as AccountLocationApi from '../../apis/accountLocationApi';
import * as AccountSubscriptionApi from '../../apis/accountSubscriptionApi';

// models
import Loader from "../shared/loader";
import AccountLocationForm from "../accounts/locations/AccountLocationForm";
import { AccountKiboshDevice } from "../../models/accountKiboshDevice";
import { AccountLocation } from "../../models/accountLocation";
import { Product } from "../../models/product";
import { User } from "../../models/auth";

type Props = {
  refresh?: boolean;
  onCancel?: () => void;
  onSave?: () => void;
};

export default function CreateNewSubscription({ refresh, onCancel, onSave }: Props) {
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [currentSubscriptions, setCurrentSubscriptions] = useState<any[]>([]);
  const [loadingLocationsList, setLoadingLocationsList] = useState(false);
  const [loadingSubscribtion, setLoadingSubscribtion] = useState(false);
  const [isCancelSubConfirmationPopupOpen, setIsCancelSubConfirmationPopupOpen] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState<AccountKiboshDevice | null>(null);
  const [selectedBillingLocation, setSelectedBillingLocation] = useState<AccountLocation | null>(null);
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  const [steps, setSteps] = useState<any[]>([
    {
      id: 1,
      title: "Kibosh Device",
    },
    {
      id: 2,
      title: "Billing Address",
    },
    {
      id: 3,
      title: "Kibosh Subscription",
    },
    {
      id: 4,
      title: "Payment",
    },
  ]);

  const abortController = new AbortController();

  const user = useSelector((state: RootState) => state.user.currentUser) as User;

  useEffect(() => {
    getBillingLocationAsync();
  }, []);

  const getBillingLocationAsync = async () => {
    const locations = await getAccountLocations(abortController.signal);
    const billing = locations.filter(x => x.billingAddress);

    if (billing.length) {
      setSelectedBillingLocation(billing[0]);
      setSteps(steps.filter(x => x.id !== 2));
    }
  }

  const getAccountLocations = async (abortSignal?: AbortSignal) => {
    setLoadingLocationsList(true);
    const response = await apiCall(AccountLocationApi.getAccountLocations(user?.accountId!, {}, abortSignal));
    setLoadingLocationsList(false);

    return response.success ? AccountLocation.toArrayOfClass(response.data?.value || []) : [];
  }

  const getKiboshDeviceActiveSubscription = async (kiboshDeviceReference: string) => {
    setLoadingSubscribtion(true);
    const response = await apiCall(AccountSubscriptionApi.getKiboshDeviceActiveSubscription(kiboshDeviceReference, abortController.signal));
    setLoadingSubscribtion(false);

    return response.success ? response.data?.value || [] : [];
  };

  const onStepCancel = () => {
    switch (currentStep) {
      case 0:
        if (onCancel) onCancel();
        break;
      case 1:
        setCurrentStep(0);
        break;
      case 2:
        setCurrentStep(1)
        break;
      case 3: // Strip logic here
        setCurrentStep(2);
        break;
    }
  };

  const onStepSave = async (value: any, step: string) => {
    switch (step) {
      case "device":
        setSelectedDevice(value);
        const data = await getKiboshDeviceActiveSubscription(value.kiboshDeviceReference);
        if (data.lenght) {
          setCurrentSubscriptions(data);
          setIsCancelSubConfirmationPopupOpen(true);
        } else {
          setCurrentStep(1);
        }
        break;
      case "location":
        setSelectedBillingLocation(value);
        setCurrentStep(2)
        break;
      case "subscription":
        setSelectedProduct(value);
        setCurrentStep(steps.filter(x => x.id === 2).length ? 3 : 2)
        break;
      case "payment": // Strip logic here
        break;

    }
  };

  const handleCancelSubscriptionRequest = async () => {
    setLoadingSubscribtion(true);
    Promise.all(currentSubscriptions.map((x: any) => handleCancelRequest(x))).then(
      (results: any) => {
        setLoadingSubscribtion(false);
        setIsCancelSubConfirmationPopupOpen(false);
        setCurrentStep(1);
      }
    );
  };

  const handleCancelRequest = async (data: any) => {
    const response = await apiCall(AccountSubscriptionApi.cancelSubscription(data));
    return response.success;
  };

  return (
    <>
      {(loadingLocationsList || loadingSubscribtion) && <Loader />}
      <view data-scroll="" data-border="none">
        <section>
          <wrapper>
            <group
            //data-space-vertical="50" 
            //data-gap="40"
            >
              <group>
                <space data-height="40"></space>
                <Steps
                  current={currentStep}
                  items={steps}
                />
              </group>
              {currentStep === 0 && (
                <AccountDevice
                  accountId={user.accountId}
                  cancelText="Back To Login"
                  okText="Save & Next"
                  selectedDevice={selectedDevice}
                  onCancel={onStepCancel}
                  onSave={(value: any) => onStepSave(value, "device")}
                />
              )}
              {currentStep === 1 && !!steps.filter(x => x.id === 2).length && (
                <AccountLocationForm
                  refresh={true}
                  cancelText="Back"
                  okText="Save & Next"
                  accountId={user.accountId!}
                  accountLocation={new AccountLocation()}
                  shouldAddBilling={true}
                  isFromSignup={true}
                  onClose={onStepCancel}
                  onSave={(value: any) => onStepSave(value, "location")} />
              )}
              {((currentStep === 2 && !!steps.filter(x => x.id === 2).length) || (currentStep === 1 && !steps.filter(x => x.id === 2).length)) && (
                <AccountSubscription
                  accountId={user.accountId}
                  cancelText="Back"
                  okText="Save & Next"
                  onCancel={onStepCancel}
                  onSave={(value: any) => onStepSave(value, "subscription")}
                />
              )}
              {((currentStep === 3 && !!steps.filter(x => x.id === 2).length) || (currentStep === 2 && !steps.filter(x => x.id === 2).length)) && (
                // <AccountPayment
                //   cancelText="Back"
                //   okText="Payment"
                //   onCancel={onStepCancel}
                //   onSave={onStepSave}
                // />
                <AccountPaymentVersion2
                  account={user}
                  selectedDevice={selectedDevice}
                  selectedBillingLocation={selectedBillingLocation}
                  selectedProduct={selectedProduct}
                  cancelText="Back"
                  okText="Payment"
                  onCancel={onStepCancel}
                  onSave={(value: any) => onStepSave(value, "payment")}
                />
              )}
            </group>
          </wrapper>
        </section>
      </view>

      {isCancelSubConfirmationPopupOpen && (
        <ConfirmationPopup
          message="This Kibosh device has active subscription, you should cancel that before Continue."
          showButton={false}
          positiveButtonText="Cancel current subscribtion and Continue"
          positiveCallback={handleCancelSubscriptionRequest}
          negativeCallback={() => {
            setIsCancelSubConfirmationPopupOpen(false);
          }}
        />
      )}
    </>
  );
}
